import React, {FC, Fragment, ReactNode, useEffect} from "react";
import CustomTopBar from "./CustomTopBar";
import CustomFooter from "./CustomFooter";
import {useDispatch, useSelector} from "react-redux";
import {IState} from "../../../types/state";
import AcceptCookies from "../../AcceptCookies";
import {AddCartToken} from "../../../store/token";
import jwt_decode, {JwtPayload} from "jwt-decode";
import {setAuth, setCustomerGroupID, setToken} from "../../../store/customer";
import {useRouter} from "next/router";
import {ICategory, IMenu} from "../../../types/productPageTypes";
import {TranslationsType} from "../../../types/homePageTypes";
import CustomHeader from "./CustomHeader";
import CustomMobileMenu from "./CustomHeader/CustomMobileMenu";
import CustomBottomNavigation from "./CustomFooter/CustomBottomNavigation";
import dynamic from "next/dynamic";
import {getCartDataMount} from "../../../store/cart";
const PopupModal = dynamic(() => import('../../popupComponents/PopupModal'), {
    ssr: false,
    loading: () => <p>Loading...</p>
});

export interface ILocales {
    code: string;
    created_at: string | null;
    updated_at: string | null;
    direction: string;
    id: number;
    locale_image: string | null;
    name: string;
    pivot: {
        channel_id: number;
        locale_id: number
    }
}

export interface ICurrencies {
    code: string;
    created_at: string | null;
    updated_at: string | null;
    id: number;
    name: string;
    symbol: string;
    exchange_rate: {
        created_at: string | null;
        updated_at: string | null;
        id: number;
        rate: string;
        target_currency: number
    }
}

export interface ISocials {
    id: number;
    name: string;
    icon: string;
    link: string;
    created_at: string;
    updated_at: string
}

export interface ICustomTopBar {
    telephone: string;
    dbName: string;
    locales: Array<ILocales>;
    currencies: Array<ICurrencies>;
    selectedLocaleImg: string
    selectedLocaleName: string
    selectedRate: ICurrencies;
    selectedLocale: string;
    translationsData: TranslationsType;
    isSocialLinksActive: Array<Record<string, string>>;
}

export interface IFirstHeader {
    dbName: string,
    logoPath: string,
    domain: string,
    menus: Array<IMenu>,
    hasBlog: string,
    selectedLocaleImg: string,
    locales: Array<ILocales>,
    cacheControlVersion: number
    translationsData: TranslationsType,
    selectedLocale: string;
    backOrderValue: string,
    selectedRate: ICurrencies;
}

export interface ISecondHeader {
    categories: Array<ICategory>;
    isTwoKings: boolean;
    dbName: string;
    selectedRate: ICurrencies;
    selectedLocale: string;
    translationsData: TranslationsType;
    backOrderValue: string;
    isSocialLinksActive: Array<Record<string, string>>;
    allowCheckoutValue: string;
    menus: Array<IMenu>;
    hasBlog: string;
    logoPath: string
}

export interface IFooter {
    selectedLocale: string;
    footerCopyRightValue: string;
    footerPoweredValue: string;
    showSubscriptionStyle: "block" | "none";
    arrFooterTrack: Array<{ href: string, id: string }>;
    footerMenus: Array<IMenu>;
    showFooterEmailStyle: "block" | "none";
    footerEmailValue: string;
    telephone: string;
    showFooterAddressStyle: "block" | "none";
    footerAddressValue: string;
    socialsFooter: Array<ISocials>;
    dbName: string;
    logoPath: string;
    hasBlog: string,
    translationsData: TranslationsType
    showFooterMenuCls: "empty_menus" | "";
    cacheControlVersion: number
}

export interface ICustomMobileMenu {
    menus: Array<IMenu>;
    categories: Array<ICategory>;
    blogValue: boolean;
    salesActiveSettings: { Tracking: string, ContactWithUs: string };
    translationsData: TranslationsType;
    selectedRate: ICurrencies;
    currencies: Array<ICurrencies>;
    phoneValue: string;
    footerAddressValue: string;
    footerEmailValue: string;
    socialsFooter: Array<ISocials>;
    dbName: string
}

export interface ILayout extends ICustomTopBar, IFirstHeader, ISecondHeader, IFooter, ICustomMobileMenu {
    logoPath: string
    children: ReactNode;
    selectedLocale: string;
    selectedLocaleName: string;
    gdprValue: number;
    gdprTitle: string;
    gdprContent: string;
    activeMobileNavElement: number;
    isMobile: boolean
}

const MainLayout: FC<ILayout> = (
    {
        children= <></>,
        telephone = "",
        dbName = "",
        selectedLocale = "en",
        domain = "",
        menus = [],
        hasBlog = "",
        blogValue = false,
        locales= [],
        currencies= [],
        selectedLocaleImg = "",
        gdprValue = 0,
        gdprTitle = "",
        gdprContent = "",
        categories = [],
        isTwoKings = false,
        translationsData = {},
        backOrderValue = '',
        isSocialLinksActive = [],
        allowCheckoutValue = '',
        footerCopyRightValue = "",
        footerPoweredValue = "",
        showSubscriptionStyle = "none",
        arrFooterTrack = [],
        footerMenus = [],
        showFooterEmailStyle = "none",
        footerEmailValue = "",
        footerAddressValue = "",
        showFooterAddressStyle = "none",
        socialsFooter = [],
        salesActiveSettings = { Tracking: "", ContactWithUs: "", },
        activeMobileNavElement = 0,
        logoPath = "",
        cacheControlVersion = 0,
        isMobile= false,
        currencies: [{code: currCode = ""} = {}] = [],
        selectedLocaleName = "",
        showFooterMenuCls = ""
    }
) => {
    const popUp = useSelector((state: IState) => state.general.popUp);
    const customer = useSelector((state: IState) => state.customer);
    const cartToken = useSelector((state: IState) => state.cartToken);
    const searchOpen = useSelector((state: IState) => state.mobileMenu.searchOpen);
    const {token: customerToken = ""} = useSelector((state: IState) => state.customer);
    const mobileMenuOpen = useSelector((state: IState) => state.mobileMenu.open);
    const dispatch = useDispatch();
    const {query: {room_id: shortRoomId = null} = {}} = useRouter();

    const backgroundClass = {
        true: "background_layer",
        false: ""
    }

    useEffect(() => {
        (async () => {
            try {
                if (!cartToken?.cartToken) {
                    const response = await fetch("/api/checkout/cart/token");
                    const res = await response.json();
                    if (res.api_token) {
                        dispatch(AddCartToken(res.api_token));
                    }
                }
            } catch (error) {
                console.error("Error fetching cart token:", error);
            }
        })();
        (async () => {
            try {
                dispatch(getCartDataMount(cartToken?.cartToken, customer, selectedLocale, currCode))
            } catch (error) {
                console.error("Error fetching cart mount:", error)
            }

        })()
    }, []);

    useEffect(() => {
        const abortController = new AbortController();
        if (customerToken) {
            const decodedToken: JwtPayload = jwt_decode(customerToken);
            if (Date.now() >= (decodedToken as { exp: number }).exp * 1000) {
                dispatch(setAuth(false));
                dispatch(setCustomerGroupID(1));
                dispatch(setToken(""));
            }
        }
        return () => abortController.abort();
    }, [customerToken]);

    useEffect(() => {
        if (shortRoomId) {
            sessionStorage.setItem("roomID", shortRoomId as string)
        }
    }, [])

    return (
        <div className="site">
            {!isMobile ? <CustomTopBar
                telephone={telephone}
                dbName={dbName}
                locales={locales}
                currencies={currencies}
                selectedLocaleImg={selectedLocaleImg}
                selectedLocaleName={selectedLocaleName}
                isSocialLinksActive={isSocialLinksActive}
                selectedLocale={selectedLocale}
                selectedRate={currencies?.[0]}
                translationsData={translationsData}
            /> : null}
            <CustomHeader
                logoPath={logoPath}
                dbName={dbName}
                domain={domain}
                menus={menus}
                hasBlog={hasBlog}
                selectedRate={currencies?.[0]}
                isTwoKings={isTwoKings}
                categories={categories}
                selectedLocale={selectedLocale}
                translationsData={translationsData}
                backOrderValue={backOrderValue}
                isSocialLinksActive={isSocialLinksActive}
                allowCheckoutValue={allowCheckoutValue}
                selectedLocaleImg={selectedLocaleImg}
                locales={locales}
                cacheControlVersion={cacheControlVersion}
                isMobile={isMobile}
            />
            {mobileMenuOpen ? <CustomMobileMenu
                menus={[...menus, ...footerMenus]}
                categories={categories}
                blogValue={blogValue}
                salesActiveSettings={salesActiveSettings}
                translationsData={translationsData}
                selectedRate={currencies?.[0]}
                currencies={currencies}
                phoneValue={telephone}
                footerAddressValue={footerAddressValue}
                footerEmailValue={footerEmailValue}
                socialsFooter={socialsFooter}
                dbName={dbName}
            /> : null}
            <div className={backgroundClass[`${searchOpen}`]}></div>
            {children}
            <CustomFooter
                selectedLocale={selectedLocale}
                footerCopyRightValue={footerCopyRightValue}
                footerPoweredValue={footerPoweredValue}
                showSubscriptionStyle={showSubscriptionStyle}
                arrFooterTrack={arrFooterTrack}
                footerMenus={footerMenus}
                showFooterEmailStyle={showFooterEmailStyle}
                footerEmailValue={footerEmailValue}
                telephone={telephone}
                footerAddressValue={footerAddressValue}
                showFooterAddressStyle={showFooterAddressStyle}
                socialsFooter={socialsFooter}
                dbName={dbName}
                translationsData={translationsData}
                hasBlog={hasBlog}
                logoPath={logoPath}
                showFooterMenuCls={showFooterMenuCls}
                cacheControlVersion={cacheControlVersion}
            />
            <CustomBottomNavigation
                activeMobileNavElement={activeMobileNavElement}
                translationsData={translationsData}
            />
            {+gdprValue ? <AcceptCookies
                translationsData={translationsData}
                gdprTitle={gdprTitle}
                gdprContent={gdprContent}
            /> : null}
            {popUp ? <PopupModal
                active={popUp}
                locale={selectedLocale}
                dbName={dbName}
                translationsData={translationsData}
                selectedCurrSymbol={currencies?.[0]?.symbol}
            />: null}
        </div>

    )
};

export default MainLayout;