import React, {FC, useEffect, useRef, useState} from "react";
import cls from "./customTopBar.module.scss"
import Link from "next/link";
import {DropDownArrow} from "../../../svg";
import {ICustomTopBar} from "./MainLayout";
import {domainUrl} from "../../../helper";
import {useRouter} from "next/router";
import CustomCurrDropDown from "./CustomCurrDropDown";
import CustomIndicatorDropDownBody from "./CustomHeader/CustomIndicators/CustomIndicatorDropDownBody";
import {useSelector} from "react-redux";
import {IState} from "../../../types/state";

const CustomTopBar: FC<ICustomTopBar> = (
    {
        dbName,
        locales = [],
        currencies: [{
            code: selectedCurr = "",
            symbol: selectedSymbol = ""
        } = {}, ...currencies] = [],
        selectedLocaleImg,
        selectedLocaleName,
        selectedRate,
        selectedLocale,
        translationsData,
        isSocialLinksActive,
    }
) => {
    const currRef = useRef<HTMLDivElement | null>(null)
    const langRef = useRef<HTMLDivElement | null>(null)
    const searchRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (langRef && langRef.current && !langRef.current.contains(e.target as Node)) {
                setOpenLang({langOpened: false, langClassName: ""})
            }
        }
        const handleSignInClick = (e: MouseEvent) => {
            if (searchRef && searchRef.current && !searchRef.current.contains(e.target as Node)) {
                setLoginDropOpen(false)
            }
        }

        document.addEventListener("mousedown", (e) => {
            handleSignInClick(e);
            handleClick(e);
        })

        return () => document.removeEventListener("mousedown", (e) => {
            handleSignInClick(e);
            handleClick(e)
        })
    }, [currRef, langRef])

    const [
        {langOpened, langClassName},
        setOpenLang
    ] = useState({langOpened: false, langClassName: ""})

    const {asPath, ...router} = useRouter();

    const [open, setOpen] = useState<boolean>(false);
    const [loginDropOpen, setLoginDropOpen] = useState<boolean>(false);

    const handleClassTypes = {"false": ["", "menu-none"], "true": ["rotT_fms", "menu-block"]};
    const signed = useSelector((state: IState) => state.customer.authenticated);

    const handleLangClick = () => {
        setOpenLang(({langOpened: prevOpen}) => {
            return {
                langOpened: !prevOpen,
                langClassName: handleClassTypes[`${!prevOpen}`][0]
            }
        })
    }

    const handleLangItemClick = (localeCode: string) => {
        router.push(asPath, asPath, {locale: localeCode}).then(r => r);
    }

    const hasDropDown = {
        true: {
            arrow: <DropDownArrow className={cls[langClassName]}/>,
            dropdown: <div className={cls[handleClassTypes[`${langOpened}`][1]]}>
                <div className={cls.menu}>
                    {locales?.map(({code, id, name, locale_image}) => (
                        <div key={id} className={cls.menu_item}
                             onClick={() => handleLangItemClick(code)}>
                            <div className={cls.menu__icon}>
                                <img src={domainUrl(`${dbName}${locale_image}`)} alt="Language" width={23} height={18} loading="lazy"/>
                            </div>
                            <p className={cls.menu__title}>
                                {name}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        },
        false: {
            arrow: null,
            dropdown: null
        }
    }

    const handleCurrencyDropdown = {
        true: <CustomCurrDropDown selectedCurr={selectedCurr} selectedSymbol={selectedSymbol} currencies={currencies}/>,
        false: null
    }

    const isAuthenticated = {
        "true":
            <div className={cls["my_account"]}>
                <Link href='/account/profile'>
                    <a>{translationsData.MyAccount}</a>
                </Link>
            </div>,
        "false":
            <div className={cls["account_indicators"]} ref={searchRef}>
                <a onClick={() => setLoginDropOpen(!loginDropOpen)}>{translationsData?.SignIn}</a>
                <div className={`${cls[`account-dropdown__${loginDropOpen ? 'opened' : 'closed'}`]}`}>
                    <CustomIndicatorDropDownBody
                        setOpen={setOpen}
                        translationsData={translationsData}
                        selectedRate={selectedRate}
                        selectedLocale={selectedLocale}
                        isSocialLinksActive={isSocialLinksActive}
                        dbName={dbName}
                    />
                </div>
                <span>|</span>
                <Link href="/signup">
                    <a>{translationsData?.SignUp}</a>
                </Link>
            </div>
    }

    return (
        <div className={cls["top-bar"]}>
            <div className="container">
                <div className={cls["top-bar-main"]}>
                    <div className={cls.topbar_buttons}>
                        <div id="forLanguage" className={cls["language-button"]} onClick={handleLangClick}
                             ref={langRef}>
                            <span>{selectedLocaleName}</span>
                            <img src={domainUrl(`${dbName}${selectedLocaleImg}`)} alt="Language" width={23} height={18}
                                 loading="lazy"/>
                            {hasDropDown[`${locales.length > 0}`].arrow}
                            {hasDropDown[`${locales.length > 0}`].dropdown}
                        </div>
                        {handleCurrencyDropdown[`${currencies.length > 0}`]}
                    </div>
                    {isAuthenticated[`${signed}`]}
                </div>
            </div>
        </div>
    )
};

export default CustomTopBar;
