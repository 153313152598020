import cls from "./customHeader.module.scss"
import React, {FC, JSX} from "react";
import {ISecondHeader} from "../MainLayout";
import CustomDepartments from "./CustomDepartments";
import CustomSearch from "./CustomSearch";
import CustomIndicatorPanel from "./CustomIndicators/CustomIndicatorPanel";
import CustomIndicatorCart from "./CustomIndicators/CustomIndicatorCart";
import {useSelector} from "react-redux";
import {IState} from "../../../../types/state";
import {ArrowRoundedDown9x6Svg} from "../../../../svg";
import CustomItemList from "./CustomItemList";
import Link from "next/link";
import Image from "next/image";
import {domainUrl} from "../../../../helper";

const showPost: (arg: string) => Record<string, JSX.Element> = (title) => {
    return {
        BlogActive: <div className={cls.header_nav_menu_item}>
            <Link href="/posts"><a>{title}</a></Link>
        </div>,
        BlogInActive: <></>
    }
};

const SecondSection: FC<ISecondHeader> = (
    {
        categories,
        isTwoKings,
        dbName,
        selectedRate,
        selectedLocale,
        translationsData,
        backOrderValue,
        isSocialLinksActive,
        allowCheckoutValue,
        menus,
        hasBlog,
        logoPath
    }
) => {
    const openSearch = useSelector((state: IState) => state.mobileMenu.searchOpen)

    const classSearchOpen = {
        true: `${cls["mobile-header__search--open"]} ${cls["mobile-header__search"]}`,
        false: cls["mobile-header__search"]
    }

    const showSearchPanelToggle: Record<"true" | "false", string> = {
        "true": "show-search-second",
        "false": "hide-search-second",
    }

    return <div className={`${cls.second_section} ${cls[showSearchPanelToggle[`${openSearch}`]]}`}>
        <div className={`${cls["nav-panel"]}`}>
            <div className={`${cls["nav-panel__container"]} container`}>
                <div className={`${cls["nav-panel__row"]}`}>
                    <div className={cls.logo_div}>
                        <Link href="/">
                            <a>
                                <Image
                                    src={domainUrl(`${dbName}/${logoPath}`)}
                                    alt="Logo"
                                    width={200}
                                    height={40}
                                    objectFit="contain"
                                    objectPosition="left"
                                    unoptimized={true}
                                    // TODO !!! this is that part which controlling CWV result
                                    priority
                                />
                            </a>
                        </Link>
                    </div>
                    <div className={`${cls["site-header__category"]} ${cls["site-header__pages"]}`}>
                        <div className={cls.header_nav_menu}>
                            <div className={cls["nav-panel__departments"]}>
                                <CustomDepartments categories={categories} isTwoKings={isTwoKings} dbName={dbName}/>
                            </div>
                            {menus.slice(0, 3)?.map(({
                                             slug = "",
                                             name = "",
                                             children = [],
                                             url_key = "",
                                             new_tab = false
                                         }, index: number) => {

                                const renderLinkHandle: (item: {
                                    url_key: string,
                                    slug: string
                                }) => Record<string, Record<string, string>> = ({url_key, slug}) => {
                                    return {
                                        "true": {
                                            "true": url_key,
                                            "false": url_key
                                        },
                                        "false": {
                                            "true": `/page/${slug}`,
                                            "false": ""
                                        }
                                    }
                                }

                                const newTabToggle = {
                                    "false": {},
                                    "true": {target: "_blank"}
                                }

                                const withSubmenu = {
                                    true: {
                                        class: `${cls.header_nav_menu_item} ${cls.with_submenu}`,
                                        arrow: <ArrowRoundedDown9x6Svg/>,
                                        menu: <CustomItemList items={children} newTabToggle={newTabToggle}
                                                              renderLinkHandle={renderLinkHandle}/>
                                    },
                                    false: {
                                        class: cls.header_nav_menu_item,
                                        arrow: null,
                                        menu: null
                                    },
                                }

                                return <div className={withSubmenu[`${!!children.length}`].class} key={index}>
                                    <Link href={renderLinkHandle({slug, url_key})[`${!!url_key}`][`${!!slug}`]}>
                                        <a {...newTabToggle[`${new_tab}`]}>
                                            {name}
                                            {withSubmenu[`${!!children.length}`].arrow}
                                        </a>
                                    </Link>
                                    {withSubmenu[`${!!children.length}`].menu}
                                </div>
                            })}
                            {/*{showPost(translationsData?.Posts)[hasBlog]}*/}
                        </div>
                    </div>
                    <div className={`${cls["site-header___search"]} ${cls["site-header__indicators"]}`}>
                        <div className={`${cls["site-header__search"]} ${classSearchOpen[`${openSearch}`]}`}>
                            <CustomSearch
                                translationsData={translationsData}
                                selectedLocale={selectedLocale}
                                dbName={dbName}
                                selectedRate={selectedRate}
                                backOrderValue={backOrderValue}
                                // clsShowSearchHandle={showSearchPanelToggle[`${openSearch}`]}
                            />
                        </div>
                        <div className={`${cls["nav-panel__indicators"]}`}>
                            <CustomIndicatorPanel
                                isSocialLinksActive={isSocialLinksActive}
                                translationsData={translationsData}
                                selectedLocale={selectedLocale}
                                selectedRate={selectedRate}
                                dbName={dbName}
                            />
                            <div className={`${cls["nav-panel__item_row"]}`}>
                                <CustomIndicatorCart
                                    allowCheckoutValue={allowCheckoutValue}
                                    translationsData={translationsData}
                                    dbName={dbName}
                                    selectedRate={selectedRate}
                                    locale={selectedLocale}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default SecondSection;