import React, {FC, useEffect, useRef, useState} from "react";
import cls from "./customTopBar.module.scss";
import {DropDownArrow} from "../../../svg";
import {ICurrencies} from "./MainLayout";
import Cookies from "js-cookie";
import {useRouter} from "next/router";
import {mobileMenuClose} from "../../../store/mobile-menu";
import {useDispatch} from "react-redux";

interface IProps {
    selectedCurr: string;
    selectedSymbol: string;
    currencies: Array<ICurrencies>
}

const CustomCurrDropDown: FC<IProps> = ({
                                            selectedCurr,
                                            selectedSymbol,
                                            currencies
                                        }) => {
    const [
        {currOpened, currClassName},
        setOpenCurr
    ] = useState({currOpened: false, currClassName: ""})
    const currRef = useRef<any>(null)
    const router = useRouter();
    const dispatch = useDispatch();

    const handleClassTypes = {"false": ["", "menu-none"], "true": ["rotT_fms", "menu-block"]};

    const handleCurrClick = () => {
        setOpenCurr(({currOpened: prevOpen,}) => {
            return {
                currOpened: !prevOpen,
                currClassName: handleClassTypes[`${!prevOpen}`][0]
            }
        })
    }

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (currRef && currRef.current && !currRef.current.contains(e.target)) {
                setOpenCurr({currOpened: false, currClassName: ""})
            }
        }

        document.addEventListener("mousedown", handleClick)
        return () => document.removeEventListener("mousedown", handleClick)
    }, [currRef])

    const handleCurrItemClick = (currencyCode: string) => {
        Cookies.set("selectedCurrency", currencyCode);
        if (router.pathname.includes("/catalog") && !router.pathname.includes("/catalog/search/[slug]")) {
            let newUrl = "/catalog";
            if (router.query.slug) {
                newUrl = `/catalog/${router.query.slug}`;
            }
            router.push(newUrl).then(() => {
                router.reload();
            });
        } else {
            router.reload();
        }
    }

    return (
        <div id="forCurrency" className={cls["language-button"]} onClick={handleCurrClick} ref={currRef}>
            <p className={cls["mobile-links__item-title"]}>{selectedCurr}</p>
            <p className={cls.mobile_currency__symbol}>{selectedSymbol}</p>
            <DropDownArrow className={`${cls[currClassName]} ${cls[handleClassTypes[`${currOpened}`][0]]}`}/>
            <div className={cls[handleClassTypes[`${currOpened}`][1]]}>
                <div className={cls.menu}>
                    {currencies?.map(({code, name, id, symbol}) => (
                        <div key={id} className={cls.menu_item} onClick={() => {
                            dispatch(mobileMenuClose())
                            handleCurrItemClick(code)
                        }}>
                            <p>{symbol}</p>
                            <p>{name}</p>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    )
}

export default CustomCurrDropDown